.form_body{
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 50;
    background: #00000050;
}

.cntr{
    display: flex;
    justify-content: center;
    position: relative;
    top: 14%;
    /* 
    /* align-items: center;
    left: 50%;
    transform: translate(-50, -50);
    margin: 0 auto !important; */
}

.modalll{
    width: 500px;
    height: auto;
    background: #fff;
    position: fixed;
    z-index: 3000;
    padding: 1rem !important;
}

.inpp{
        display: block;
        width: 90%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.1rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        /* transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; */
        transition: all .1s linear;
        margin: 10px;
        /* border: none; */
        outline: 0;
}

.inpp:focus{
    border:1.3px solid #007bff !important;
}

.emaill{
    background: #e9ecef;
}

.btn{
    background-color: #007bff;
    color: #fff;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 30px;
    /* background-color: transparent; */
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.txt_danger{
    color: #dc3545!important;
    font-size: .875em;
    font-weight: bold;
}

@media (max-width: 560px) {
    .modalll{
        width: 100vw;
    }
}