.docs{
    cursor: pointer;
}

.aiii{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    margin-top:-2em;

}

.files_wrapper{
    margin: 1.5cm;
}
